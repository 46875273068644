@import "../config/imports";

.confirmation {
    margin: -50px auto 120px auto;
    max-width: 1029px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 30px;
    text-align: center;
    position: relative;
    z-index: 0;
}
.confirmation h3 {
    text-transform: none;
    font-size: 24px;
    font-weight: bold;
    line-height: 33px;
}
.confirmation p {
    font-size: 18px;
    line-height: 36px;
    color: #000000;
    margin: 30px 0 45px 0;
}
#orderPrice { font-style: italic }
.confirmation p.unsuccess-confirmation {
    color: #BD362F;
}

i.large-badge  {
    font-size: 50px;
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0px 0px 2px;
    padding: 0.5em 0.6em;
    min-width: 100px;
    min-height: 100px;
}

